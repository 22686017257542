import { default as testlZXtVY46F4Meta } from "/home/runner/work/atlantic/atlantic/agents/pages/test.vue?macro=true";
import { default as indexHpsAmcRhzJMeta } from "/home/runner/work/atlantic/atlantic/agents/modules/commissions/pages/index.vue?macro=true";
import { default as indexsVst9dZrbyMeta } from "/home/runner/work/atlantic/atlantic/agents/modules/example/pages/index.vue?macro=true";
import { default as indexea7owdAJw3Meta } from "/home/runner/work/atlantic/atlantic/agents/modules/account/pages/index.vue?macro=true";
import { default as detailsGivkotyy9xMeta } from "/home/runner/work/atlantic/atlantic/agents/modules/account/pages/personal/details.vue?macro=true";
import { default as password9Tdna71MnkMeta } from "/home/runner/work/atlantic/atlantic/agents/modules/account/pages/personal/password.vue?macro=true";
import { default as detailsnS8sIqlLngMeta } from "/home/runner/work/atlantic/atlantic/agents/modules/account/pages/company/details.vue?macro=true";
import { default as enquiriesmwAMhkqA07Meta } from "/home/runner/work/atlantic/atlantic/agents/modules/account/pages/company/enquiries.vue?macro=true";
import { default as utilitiesyEnoR0hGg0Meta } from "/home/runner/work/atlantic/atlantic/agents/modules/account/pages/company/utilities.vue?macro=true";
import { default as tenanciesFuNXEuRSRpMeta } from "/home/runner/work/atlantic/atlantic/agents/modules/account/pages/company/tenancies.vue?macro=true";
import { default as indexoq196Am6bkMeta } from "/home/runner/work/atlantic/atlantic/agents/modules/account/pages/company/user/management/index.vue?macro=true";
import { default as historyot2NqBIF1RMeta } from "/home/runner/work/atlantic/atlantic/agents/modules/account/pages/company/user/history.vue?macro=true";
import { default as addIw2tXtB1FJMeta } from "/home/runner/work/atlantic/atlantic/agents/modules/account/pages/company/user/management/add.vue?macro=true";
import { default as _91id_93a69FxxYyqmMeta } from "/home/runner/work/atlantic/atlantic/agents/modules/account/pages/company/user/management/edit/[id].vue?macro=true";
import { default as indexruC3SUX128Meta } from "/home/runner/work/atlantic/atlantic/agents/modules/analytics/pages/index.vue?macro=true";
import { default as enquirieswCiVDAfmcMMeta } from "/home/runner/work/atlantic/atlantic/agents/modules/analytics/pages/enquiries.vue?macro=true";
import { default as utilitiesJ2u2LcvMK3Meta } from "/home/runner/work/atlantic/atlantic/agents/modules/analytics/pages/utilities.vue?macro=true";
import { default as discoveryJQGfhx6OcsMeta } from "/home/runner/work/atlantic/atlantic/agents/modules/analytics/pages/discovery.vue?macro=true";
import { default as portfolioghnK8dRlZiMeta } from "/home/runner/work/atlantic/atlantic/agents/modules/analytics/pages/portfolio.vue?macro=true";
import { default as marketIF7tYhKE01Meta } from "/home/runner/work/atlantic/atlantic/agents/modules/analytics/pages/market.vue?macro=true";
import { default as indexCPzIllxTl9Meta } from "/home/runner/work/atlantic/atlantic/agents/modules/auth/pages/login/index.vue?macro=true";
import { default as indexqxxTEtWSYhMeta } from "/home/runner/work/atlantic/atlantic/agents/modules/auth/pages/forgot-password/index.vue?macro=true";
import { default as sentxQPRGByYf8Meta } from "/home/runner/work/atlantic/atlantic/agents/modules/auth/pages/forgot-password/sent.vue?macro=true";
import { default as _91token_931PK1N8HCBgMeta } from "/home/runner/work/atlantic/atlantic/agents/modules/auth/pages/reset-password/[token].vue?macro=true";
import { default as indexoiZF9q0pxEMeta } from "/home/runner/work/atlantic/atlantic/agents/modules/auth/pages/accept-invite/index.vue?macro=true";
import { default as featuresFwbrDXVaAvMeta } from "/home/runner/work/atlantic/atlantic/agents/modules/checkout/pages/checkout/features.vue?macro=true";
import { default as paymentmHAErNtjeiMeta } from "/home/runner/work/atlantic/atlantic/agents/modules/checkout/pages/checkout/payment.vue?macro=true";
import { default as indexFuPl84Z0WcMeta } from "/home/runner/work/atlantic/atlantic/agents/modules/checkout/pages/index.vue?macro=true";
import { default as successbZKOD2uLsbMeta } from "/home/runner/work/atlantic/atlantic/agents/modules/checkout/pages/success.vue?macro=true";
import { default as indexv2Wbq1ob0sMeta } from "/home/runner/work/atlantic/atlantic/agents/modules/dashboard/pages/index.vue?macro=true";
import { default as indexnaKmZ9eU1qMeta } from "/home/runner/work/atlantic/atlantic/agents/modules/enquiries/pages/index.vue?macro=true";
import { default as indexOLBPKXd67HMeta } from "/home/runner/work/atlantic/atlantic/agents/modules/properties/pages/index.vue?macro=true";
import { default as indexn3ptGJnd8JMeta } from "/home/runner/work/atlantic/atlantic/agents/modules/properties/pages/import/index.vue?macro=true";
import { default as address93Lgb4GFZWMeta } from "/home/runner/work/atlantic/atlantic/agents/modules/properties/pages/property/address.vue?macro=true";
import { default as aboutMO8lpTj6mqMeta } from "/home/runner/work/atlantic/atlantic/agents/modules/properties/pages/property/about.vue?macro=true";
import { default as listingp7At54SadlMeta } from "/home/runner/work/atlantic/atlantic/agents/modules/properties/pages/property/listing.vue?macro=true";
import { default as mediaQFieJav5KQMeta } from "/home/runner/work/atlantic/atlantic/agents/modules/properties/pages/property/media.vue?macro=true";
import { default as propertyVan3WhkrJbMeta } from "/home/runner/work/atlantic/atlantic/agents/modules/properties/pages/property.vue?macro=true";
import { default as editbRO2EshNLxMeta } from "/home/runner/work/atlantic/atlantic/agents/modules/properties/pages/property/[uuid]/edit.vue?macro=true";
import { default as partnershipAQZU79xdr4Meta } from "/home/runner/work/atlantic/atlantic/agents/modules/resources/pages/partnership.vue?macro=true";
import { default as marketing_45materialfGTXwxNjwfMeta } from "/home/runner/work/atlantic/atlantic/agents/modules/resources/pages/marketing-material.vue?macro=true";
import { default as indexlfJIe0xrhqMeta } from "/home/runner/work/atlantic/atlantic/agents/modules/tenancies/pages/index.vue?macro=true";
import { default as detailsq81yrZGX53Meta } from "/home/runner/work/atlantic/atlantic/agents/modules/tenancies/pages/agreement/details.vue?macro=true";
import { default as paymentspKOwKrJNBBMeta } from "/home/runner/work/atlantic/atlantic/agents/modules/tenancies/pages/agreement/payments.vue?macro=true";
import { default as tenantsIWn95syl3NMeta } from "/home/runner/work/atlantic/atlantic/agents/modules/tenancies/pages/agreement/tenants.vue?macro=true";
import { default as deposithfislEOClsMeta } from "/home/runner/work/atlantic/atlantic/agents/modules/tenancies/pages/agreement/deposit.vue?macro=true";
import { default as signing0Ac9b8XxcfMeta } from "/home/runner/work/atlantic/atlantic/agents/modules/tenancies/pages/agreement/signing.vue?macro=true";
import { default as agreementMA3qFkRVM6Meta } from "/home/runner/work/atlantic/atlantic/agents/modules/tenancies/pages/agreement.vue?macro=true";
import { default as confirmation96SPXyZjrKMeta } from "/home/runner/work/atlantic/atlantic/agents/modules/tenancies/pages/agreement/confirmation.vue?macro=true";
import { default as indexAUpztgCMitMeta } from "/home/runner/work/atlantic/atlantic/agents/modules/tenancies/pages/agreement/[uuid]/index.vue?macro=true";
import { default as previewTTwrAIddr4Meta } from "/home/runner/work/atlantic/atlantic/agents/modules/tenancies/pages/agreement/preview/preview.vue?macro=true";
import { default as index0x7qVPRCEDMeta } from "/home/runner/work/atlantic/atlantic/agents/modules/utilities/pages/index.vue?macro=true";
import { default as details60OcmFc3VHMeta } from "/home/runner/work/atlantic/atlantic/agents/modules/utilities/pages/order/details.vue?macro=true";
import { default as tenantsPrW44P4LfoMeta } from "/home/runner/work/atlantic/atlantic/agents/modules/utilities/pages/order/tenants.vue?macro=true";
import { default as summaryH9oQtXKle8Meta } from "/home/runner/work/atlantic/atlantic/agents/modules/utilities/pages/order/summary.vue?macro=true";
import { default as preview1ewtRG27IfMeta } from "/home/runner/work/atlantic/atlantic/agents/modules/utilities/pages/order/preview.vue?macro=true";
import { default as orderukrtoYjDoSMeta } from "/home/runner/work/atlantic/atlantic/agents/modules/utilities/pages/order.vue?macro=true";
import { default as confirmationXrPqHPgV5eMeta } from "/home/runner/work/atlantic/atlantic/agents/modules/utilities/pages/order/confirmation.vue?macro=true";
import { default as indexFoGSUPPCYkMeta } from "/home/runner/work/atlantic/atlantic/agents/modules/utilities/pages/order/[uuid]/index.vue?macro=true";
export default [
  {
    name: testlZXtVY46F4Meta?.name ?? "test",
    path: testlZXtVY46F4Meta?.path ?? "/test",
    meta: testlZXtVY46F4Meta || {},
    alias: testlZXtVY46F4Meta?.alias || [],
    redirect: testlZXtVY46F4Meta?.redirect,
    component: () => import("/home/runner/work/atlantic/atlantic/agents/pages/test.vue").then(m => m.default || m)
  },
  {
    name: indexHpsAmcRhzJMeta?.name ?? "commissions",
    path: indexHpsAmcRhzJMeta?.path ?? "/commissions",
    meta: indexHpsAmcRhzJMeta || {},
    alias: indexHpsAmcRhzJMeta?.alias || [],
    redirect: indexHpsAmcRhzJMeta?.redirect,
    component: () => import("/home/runner/work/atlantic/atlantic/agents/modules/commissions/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexsVst9dZrbyMeta?.name ?? "example",
    path: indexsVst9dZrbyMeta?.path ?? "/example",
    meta: indexsVst9dZrbyMeta || {},
    alias: indexsVst9dZrbyMeta?.alias || [],
    redirect: indexsVst9dZrbyMeta?.redirect,
    component: () => import("/home/runner/work/atlantic/atlantic/agents/modules/example/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexea7owdAJw3Meta?.name ?? "account-index",
    path: indexea7owdAJw3Meta?.path ?? "/account",
    meta: indexea7owdAJw3Meta || {},
    alias: indexea7owdAJw3Meta?.alias || [],
    redirect: indexea7owdAJw3Meta?.redirect,
    component: () => import("/home/runner/work/atlantic/atlantic/agents/modules/account/pages/index.vue").then(m => m.default || m)
  },
  {
    name: detailsGivkotyy9xMeta?.name ?? "personal-details-settings",
    path: detailsGivkotyy9xMeta?.path ?? "/account/personal/details",
    meta: detailsGivkotyy9xMeta || {},
    alias: detailsGivkotyy9xMeta?.alias || [],
    redirect: detailsGivkotyy9xMeta?.redirect,
    component: () => import("/home/runner/work/atlantic/atlantic/agents/modules/account/pages/personal/details.vue").then(m => m.default || m)
  },
  {
    name: password9Tdna71MnkMeta?.name ?? "personal-password-settings",
    path: password9Tdna71MnkMeta?.path ?? "/account/personal/password",
    meta: password9Tdna71MnkMeta || {},
    alias: password9Tdna71MnkMeta?.alias || [],
    redirect: password9Tdna71MnkMeta?.redirect,
    component: () => import("/home/runner/work/atlantic/atlantic/agents/modules/account/pages/personal/password.vue").then(m => m.default || m)
  },
  {
    name: detailsnS8sIqlLngMeta?.name ?? "company-details-settings",
    path: detailsnS8sIqlLngMeta?.path ?? "/account/company/details",
    meta: detailsnS8sIqlLngMeta || {},
    alias: detailsnS8sIqlLngMeta?.alias || [],
    redirect: detailsnS8sIqlLngMeta?.redirect,
    component: () => import("/home/runner/work/atlantic/atlantic/agents/modules/account/pages/company/details.vue").then(m => m.default || m)
  },
  {
    name: enquiriesmwAMhkqA07Meta?.name ?? "company-enquiries-settings",
    path: enquiriesmwAMhkqA07Meta?.path ?? "/account/company/enquiries",
    meta: enquiriesmwAMhkqA07Meta || {},
    alias: enquiriesmwAMhkqA07Meta?.alias || [],
    redirect: enquiriesmwAMhkqA07Meta?.redirect,
    component: () => import("/home/runner/work/atlantic/atlantic/agents/modules/account/pages/company/enquiries.vue").then(m => m.default || m)
  },
  {
    name: utilitiesyEnoR0hGg0Meta?.name ?? "company-utilities-settings",
    path: utilitiesyEnoR0hGg0Meta?.path ?? "/account/company/utilities",
    meta: utilitiesyEnoR0hGg0Meta || {},
    alias: utilitiesyEnoR0hGg0Meta?.alias || [],
    redirect: utilitiesyEnoR0hGg0Meta?.redirect,
    component: () => import("/home/runner/work/atlantic/atlantic/agents/modules/account/pages/company/utilities.vue").then(m => m.default || m)
  },
  {
    name: tenanciesFuNXEuRSRpMeta?.name ?? "company-tenancies-settings",
    path: tenanciesFuNXEuRSRpMeta?.path ?? "/account/company/tenancies",
    meta: tenanciesFuNXEuRSRpMeta || {},
    alias: tenanciesFuNXEuRSRpMeta?.alias || [],
    redirect: tenanciesFuNXEuRSRpMeta?.redirect,
    component: () => import("/home/runner/work/atlantic/atlantic/agents/modules/account/pages/company/tenancies.vue").then(m => m.default || m)
  },
  {
    name: indexoq196Am6bkMeta?.name ?? "company-user-management",
    path: indexoq196Am6bkMeta?.path ?? "/account/company/user/management",
    meta: indexoq196Am6bkMeta || {},
    alias: indexoq196Am6bkMeta?.alias || [],
    redirect: indexoq196Am6bkMeta?.redirect,
    component: () => import("/home/runner/work/atlantic/atlantic/agents/modules/account/pages/company/user/management/index.vue").then(m => m.default || m)
  },
  {
    name: historyot2NqBIF1RMeta?.name ?? "company-user-history",
    path: historyot2NqBIF1RMeta?.path ?? "/account/company/user/history",
    meta: historyot2NqBIF1RMeta || {},
    alias: historyot2NqBIF1RMeta?.alias || [],
    redirect: historyot2NqBIF1RMeta?.redirect,
    component: () => import("/home/runner/work/atlantic/atlantic/agents/modules/account/pages/company/user/history.vue").then(m => m.default || m)
  },
  {
    name: addIw2tXtB1FJMeta?.name ?? "company-user-add",
    path: addIw2tXtB1FJMeta?.path ?? "/account/company/user/management/add",
    meta: addIw2tXtB1FJMeta || {},
    alias: addIw2tXtB1FJMeta?.alias || [],
    redirect: addIw2tXtB1FJMeta?.redirect,
    component: () => import("/home/runner/work/atlantic/atlantic/agents/modules/account/pages/company/user/management/add.vue").then(m => m.default || m)
  },
  {
    name: _91id_93a69FxxYyqmMeta?.name ?? "company-user-edit",
    path: _91id_93a69FxxYyqmMeta?.path ?? "/account/company/user/management/edit/:id",
    meta: _91id_93a69FxxYyqmMeta || {},
    alias: _91id_93a69FxxYyqmMeta?.alias || [],
    redirect: _91id_93a69FxxYyqmMeta?.redirect,
    component: () => import("/home/runner/work/atlantic/atlantic/agents/modules/account/pages/company/user/management/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: indexruC3SUX128Meta?.name ?? "analytics",
    path: indexruC3SUX128Meta?.path ?? "/analytics",
    meta: indexruC3SUX128Meta || {},
    alias: indexruC3SUX128Meta?.alias || [],
    redirect: indexruC3SUX128Meta?.redirect,
    component: () => import("/home/runner/work/atlantic/atlantic/agents/modules/analytics/pages/index.vue").then(m => m.default || m)
  },
  {
    name: enquirieswCiVDAfmcMMeta?.name ?? "analytics-enquiries",
    path: enquirieswCiVDAfmcMMeta?.path ?? "/analytics/enquiries",
    meta: enquirieswCiVDAfmcMMeta || {},
    alias: enquirieswCiVDAfmcMMeta?.alias || [],
    redirect: enquirieswCiVDAfmcMMeta?.redirect,
    component: () => import("/home/runner/work/atlantic/atlantic/agents/modules/analytics/pages/enquiries.vue").then(m => m.default || m)
  },
  {
    name: utilitiesJ2u2LcvMK3Meta?.name ?? "analytics-utilities",
    path: utilitiesJ2u2LcvMK3Meta?.path ?? "/analytics/utilities",
    meta: utilitiesJ2u2LcvMK3Meta || {},
    alias: utilitiesJ2u2LcvMK3Meta?.alias || [],
    redirect: utilitiesJ2u2LcvMK3Meta?.redirect,
    component: () => import("/home/runner/work/atlantic/atlantic/agents/modules/analytics/pages/utilities.vue").then(m => m.default || m)
  },
  {
    name: discoveryJQGfhx6OcsMeta?.name ?? "analytics-discovery",
    path: discoveryJQGfhx6OcsMeta?.path ?? "/analytics/discovery",
    meta: discoveryJQGfhx6OcsMeta || {},
    alias: discoveryJQGfhx6OcsMeta?.alias || [],
    redirect: discoveryJQGfhx6OcsMeta?.redirect,
    component: () => import("/home/runner/work/atlantic/atlantic/agents/modules/analytics/pages/discovery.vue").then(m => m.default || m)
  },
  {
    name: portfolioghnK8dRlZiMeta?.name ?? "analytics-portfolio",
    path: portfolioghnK8dRlZiMeta?.path ?? "/analytics/portfolio",
    meta: portfolioghnK8dRlZiMeta || {},
    alias: portfolioghnK8dRlZiMeta?.alias || [],
    redirect: portfolioghnK8dRlZiMeta?.redirect,
    component: () => import("/home/runner/work/atlantic/atlantic/agents/modules/analytics/pages/portfolio.vue").then(m => m.default || m)
  },
  {
    name: marketIF7tYhKE01Meta?.name ?? "analytics-market",
    path: marketIF7tYhKE01Meta?.path ?? "/analytics/market",
    meta: marketIF7tYhKE01Meta || {},
    alias: marketIF7tYhKE01Meta?.alias || [],
    redirect: marketIF7tYhKE01Meta?.redirect,
    component: () => import("/home/runner/work/atlantic/atlantic/agents/modules/analytics/pages/market.vue").then(m => m.default || m)
  },
  {
    name: indexCPzIllxTl9Meta?.name ?? "login",
    path: indexCPzIllxTl9Meta?.path ?? "/login",
    meta: indexCPzIllxTl9Meta || {},
    alias: indexCPzIllxTl9Meta?.alias || [],
    redirect: indexCPzIllxTl9Meta?.redirect,
    component: () => import("/home/runner/work/atlantic/atlantic/agents/modules/auth/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: indexqxxTEtWSYhMeta?.name ?? "forgot-password",
    path: indexqxxTEtWSYhMeta?.path ?? "/forgot-password",
    meta: indexqxxTEtWSYhMeta || {},
    alias: indexqxxTEtWSYhMeta?.alias || [],
    redirect: indexqxxTEtWSYhMeta?.redirect,
    component: () => import("/home/runner/work/atlantic/atlantic/agents/modules/auth/pages/forgot-password/index.vue").then(m => m.default || m)
  },
  {
    name: sentxQPRGByYf8Meta?.name ?? "forgot-password-sent",
    path: sentxQPRGByYf8Meta?.path ?? "/forgot-password/sent",
    meta: sentxQPRGByYf8Meta || {},
    alias: sentxQPRGByYf8Meta?.alias || [],
    redirect: sentxQPRGByYf8Meta?.redirect,
    component: () => import("/home/runner/work/atlantic/atlantic/agents/modules/auth/pages/forgot-password/sent.vue").then(m => m.default || m)
  },
  {
    name: _91token_931PK1N8HCBgMeta?.name ?? "reset-password",
    path: _91token_931PK1N8HCBgMeta?.path ?? "/reset-password/:token",
    meta: _91token_931PK1N8HCBgMeta || {},
    alias: _91token_931PK1N8HCBgMeta?.alias || [],
    redirect: _91token_931PK1N8HCBgMeta?.redirect,
    component: () => import("/home/runner/work/atlantic/atlantic/agents/modules/auth/pages/reset-password/[token].vue").then(m => m.default || m)
  },
  {
    name: indexoiZF9q0pxEMeta?.name ?? "accept-invite",
    path: indexoiZF9q0pxEMeta?.path ?? "/accept-invite",
    meta: indexoiZF9q0pxEMeta || {},
    alias: indexoiZF9q0pxEMeta?.alias || [],
    redirect: indexoiZF9q0pxEMeta?.redirect,
    component: () => import("/home/runner/work/atlantic/atlantic/agents/modules/auth/pages/accept-invite/index.vue").then(m => m.default || m)
  },
  {
    name: indexFuPl84Z0WcMeta?.name ?? "checkout",
    path: indexFuPl84Z0WcMeta?.path ?? "/checkout",
    meta: indexFuPl84Z0WcMeta || {},
    alias: indexFuPl84Z0WcMeta?.alias || [],
    redirect: indexFuPl84Z0WcMeta?.redirect,
    component: () => import("/home/runner/work/atlantic/atlantic/agents/modules/checkout/pages/index.vue").then(m => m.default || m),
    children: [
  {
    name: featuresFwbrDXVaAvMeta?.name ?? "checkout-features",
    path: featuresFwbrDXVaAvMeta?.path ?? "features",
    meta: featuresFwbrDXVaAvMeta || {},
    alias: featuresFwbrDXVaAvMeta?.alias || [],
    redirect: featuresFwbrDXVaAvMeta?.redirect,
    component: () => import("/home/runner/work/atlantic/atlantic/agents/modules/checkout/pages/checkout/features.vue").then(m => m.default || m)
  },
  {
    name: paymentmHAErNtjeiMeta?.name ?? "checkout-payment",
    path: paymentmHAErNtjeiMeta?.path ?? "payment",
    meta: paymentmHAErNtjeiMeta || {},
    alias: paymentmHAErNtjeiMeta?.alias || [],
    redirect: paymentmHAErNtjeiMeta?.redirect,
    component: () => import("/home/runner/work/atlantic/atlantic/agents/modules/checkout/pages/checkout/payment.vue").then(m => m.default || m)
  }
]
  },
  {
    name: successbZKOD2uLsbMeta?.name ?? "checkout-success",
    path: successbZKOD2uLsbMeta?.path ?? "/checkout/success",
    meta: successbZKOD2uLsbMeta || {},
    alias: successbZKOD2uLsbMeta?.alias || [],
    redirect: successbZKOD2uLsbMeta?.redirect,
    component: () => import("/home/runner/work/atlantic/atlantic/agents/modules/checkout/pages/success.vue").then(m => m.default || m)
  },
  {
    name: indexv2Wbq1ob0sMeta?.name ?? "dashboard",
    path: indexv2Wbq1ob0sMeta?.path ?? "/",
    meta: indexv2Wbq1ob0sMeta || {},
    alias: indexv2Wbq1ob0sMeta?.alias || [],
    redirect: indexv2Wbq1ob0sMeta?.redirect,
    component: () => import("/home/runner/work/atlantic/atlantic/agents/modules/dashboard/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexnaKmZ9eU1qMeta?.name ?? "enquiries",
    path: indexnaKmZ9eU1qMeta?.path ?? "/enquiries",
    meta: indexnaKmZ9eU1qMeta || {},
    alias: indexnaKmZ9eU1qMeta?.alias || [],
    redirect: indexnaKmZ9eU1qMeta?.redirect,
    component: () => import("/home/runner/work/atlantic/atlantic/agents/modules/enquiries/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexOLBPKXd67HMeta?.name ?? "properties-index",
    path: indexOLBPKXd67HMeta?.path ?? "/properties",
    meta: indexOLBPKXd67HMeta || {},
    alias: indexOLBPKXd67HMeta?.alias || [],
    redirect: indexOLBPKXd67HMeta?.redirect,
    component: () => import("/home/runner/work/atlantic/atlantic/agents/modules/properties/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexn3ptGJnd8JMeta?.name ?? "properties-import",
    path: indexn3ptGJnd8JMeta?.path ?? "/properties/import",
    meta: indexn3ptGJnd8JMeta || {},
    alias: indexn3ptGJnd8JMeta?.alias || [],
    redirect: indexn3ptGJnd8JMeta?.redirect,
    component: () => import("/home/runner/work/atlantic/atlantic/agents/modules/properties/pages/import/index.vue").then(m => m.default || m)
  },
  {
    name: propertyVan3WhkrJbMeta?.name ?? "properties-property-create",
    path: propertyVan3WhkrJbMeta?.path ?? "/property/create",
    meta: propertyVan3WhkrJbMeta || {},
    alias: propertyVan3WhkrJbMeta?.alias || [],
    redirect: {"name":"properties-property-create-address"},
    component: () => import("/home/runner/work/atlantic/atlantic/agents/modules/properties/pages/property.vue").then(m => m.default || m),
    children: [
  {
    name: address93Lgb4GFZWMeta?.name ?? "properties-property-create-address",
    path: address93Lgb4GFZWMeta?.path ?? "address",
    meta: address93Lgb4GFZWMeta || {},
    alias: address93Lgb4GFZWMeta?.alias || [],
    redirect: address93Lgb4GFZWMeta?.redirect,
    component: () => import("/home/runner/work/atlantic/atlantic/agents/modules/properties/pages/property/address.vue").then(m => m.default || m)
  },
  {
    name: aboutMO8lpTj6mqMeta?.name ?? "properties-property-create-about",
    path: aboutMO8lpTj6mqMeta?.path ?? "about",
    meta: aboutMO8lpTj6mqMeta || {},
    alias: aboutMO8lpTj6mqMeta?.alias || [],
    redirect: aboutMO8lpTj6mqMeta?.redirect,
    component: () => import("/home/runner/work/atlantic/atlantic/agents/modules/properties/pages/property/about.vue").then(m => m.default || m)
  },
  {
    name: listingp7At54SadlMeta?.name ?? "properties-property-create-listing",
    path: listingp7At54SadlMeta?.path ?? "listing",
    meta: listingp7At54SadlMeta || {},
    alias: listingp7At54SadlMeta?.alias || [],
    redirect: listingp7At54SadlMeta?.redirect,
    component: () => import("/home/runner/work/atlantic/atlantic/agents/modules/properties/pages/property/listing.vue").then(m => m.default || m)
  },
  {
    name: mediaQFieJav5KQMeta?.name ?? "properties-property-create-media",
    path: mediaQFieJav5KQMeta?.path ?? "media",
    meta: mediaQFieJav5KQMeta || {},
    alias: mediaQFieJav5KQMeta?.alias || [],
    redirect: mediaQFieJav5KQMeta?.redirect,
    component: () => import("/home/runner/work/atlantic/atlantic/agents/modules/properties/pages/property/media.vue").then(m => m.default || m)
  }
]
  },
  {
    name: editbRO2EshNLxMeta?.name ?? "properties-property-edit",
    path: editbRO2EshNLxMeta?.path ?? "/property/:uuid/edit",
    meta: editbRO2EshNLxMeta || {},
    alias: editbRO2EshNLxMeta?.alias || [],
    redirect: {"name":"properties-property-edit-address"},
    component: () => import("/home/runner/work/atlantic/atlantic/agents/modules/properties/pages/property/[uuid]/edit.vue").then(m => m.default || m),
    children: [
  {
    name: address93Lgb4GFZWMeta?.name ?? "properties-property-edit-address",
    path: address93Lgb4GFZWMeta?.path ?? "address",
    meta: address93Lgb4GFZWMeta || {},
    alias: address93Lgb4GFZWMeta?.alias || [],
    redirect: address93Lgb4GFZWMeta?.redirect,
    component: () => import("/home/runner/work/atlantic/atlantic/agents/modules/properties/pages/property/address.vue").then(m => m.default || m)
  },
  {
    name: aboutMO8lpTj6mqMeta?.name ?? "properties-property-edit-about",
    path: aboutMO8lpTj6mqMeta?.path ?? "about",
    meta: aboutMO8lpTj6mqMeta || {},
    alias: aboutMO8lpTj6mqMeta?.alias || [],
    redirect: aboutMO8lpTj6mqMeta?.redirect,
    component: () => import("/home/runner/work/atlantic/atlantic/agents/modules/properties/pages/property/about.vue").then(m => m.default || m)
  },
  {
    name: listingp7At54SadlMeta?.name ?? "properties-property-edit-listing",
    path: listingp7At54SadlMeta?.path ?? "listing",
    meta: listingp7At54SadlMeta || {},
    alias: listingp7At54SadlMeta?.alias || [],
    redirect: listingp7At54SadlMeta?.redirect,
    component: () => import("/home/runner/work/atlantic/atlantic/agents/modules/properties/pages/property/listing.vue").then(m => m.default || m)
  },
  {
    name: mediaQFieJav5KQMeta?.name ?? "properties-property-edit-media",
    path: mediaQFieJav5KQMeta?.path ?? "media",
    meta: mediaQFieJav5KQMeta || {},
    alias: mediaQFieJav5KQMeta?.alias || [],
    redirect: mediaQFieJav5KQMeta?.redirect,
    component: () => import("/home/runner/work/atlantic/atlantic/agents/modules/properties/pages/property/media.vue").then(m => m.default || m)
  }
]
  },
  {
    name: partnershipAQZU79xdr4Meta?.name ?? "resources-partnership",
    path: partnershipAQZU79xdr4Meta?.path ?? "/resources/partnership",
    meta: partnershipAQZU79xdr4Meta || {},
    alias: partnershipAQZU79xdr4Meta?.alias || [],
    redirect: partnershipAQZU79xdr4Meta?.redirect,
    component: () => import("/home/runner/work/atlantic/atlantic/agents/modules/resources/pages/partnership.vue").then(m => m.default || m)
  },
  {
    name: marketing_45materialfGTXwxNjwfMeta?.name ?? "resources-marketing-material",
    path: marketing_45materialfGTXwxNjwfMeta?.path ?? "/resources/marketing-material",
    meta: marketing_45materialfGTXwxNjwfMeta || {},
    alias: marketing_45materialfGTXwxNjwfMeta?.alias || [],
    redirect: marketing_45materialfGTXwxNjwfMeta?.redirect,
    component: () => import("/home/runner/work/atlantic/atlantic/agents/modules/resources/pages/marketing-material.vue").then(m => m.default || m)
  },
  {
    name: indexlfJIe0xrhqMeta?.name ?? "tenancies",
    path: indexlfJIe0xrhqMeta?.path ?? "/tenancies",
    meta: indexlfJIe0xrhqMeta || {},
    alias: indexlfJIe0xrhqMeta?.alias || [],
    redirect: indexlfJIe0xrhqMeta?.redirect,
    component: () => import("/home/runner/work/atlantic/atlantic/agents/modules/tenancies/pages/index.vue").then(m => m.default || m)
  },
  {
    name: agreementMA3qFkRVM6Meta?.name ?? "tenancies-agreement",
    path: agreementMA3qFkRVM6Meta?.path ?? "/tenancies/agreement",
    meta: agreementMA3qFkRVM6Meta || {},
    alias: agreementMA3qFkRVM6Meta?.alias || [],
    redirect: {"name":"tenancies-agreement-details"},
    component: () => import("/home/runner/work/atlantic/atlantic/agents/modules/tenancies/pages/agreement.vue").then(m => m.default || m),
    children: [
  {
    name: detailsq81yrZGX53Meta?.name ?? "tenancies-agreement-details",
    path: detailsq81yrZGX53Meta?.path ?? "details",
    meta: detailsq81yrZGX53Meta || {},
    alias: detailsq81yrZGX53Meta?.alias || [],
    redirect: detailsq81yrZGX53Meta?.redirect,
    component: () => import("/home/runner/work/atlantic/atlantic/agents/modules/tenancies/pages/agreement/details.vue").then(m => m.default || m)
  },
  {
    name: paymentspKOwKrJNBBMeta?.name ?? "tenancies-agreement-payments",
    path: paymentspKOwKrJNBBMeta?.path ?? "payments",
    meta: paymentspKOwKrJNBBMeta || {},
    alias: paymentspKOwKrJNBBMeta?.alias || [],
    redirect: paymentspKOwKrJNBBMeta?.redirect,
    component: () => import("/home/runner/work/atlantic/atlantic/agents/modules/tenancies/pages/agreement/payments.vue").then(m => m.default || m)
  },
  {
    name: tenantsIWn95syl3NMeta?.name ?? "tenancies-agreement-tenants",
    path: tenantsIWn95syl3NMeta?.path ?? "tenants",
    meta: tenantsIWn95syl3NMeta || {},
    alias: tenantsIWn95syl3NMeta?.alias || [],
    redirect: tenantsIWn95syl3NMeta?.redirect,
    component: () => import("/home/runner/work/atlantic/atlantic/agents/modules/tenancies/pages/agreement/tenants.vue").then(m => m.default || m)
  },
  {
    name: deposithfislEOClsMeta?.name ?? "tenancies-agreement-deposit",
    path: deposithfislEOClsMeta?.path ?? "deposit",
    meta: deposithfislEOClsMeta || {},
    alias: deposithfislEOClsMeta?.alias || [],
    redirect: deposithfislEOClsMeta?.redirect,
    component: () => import("/home/runner/work/atlantic/atlantic/agents/modules/tenancies/pages/agreement/deposit.vue").then(m => m.default || m)
  },
  {
    name: signing0Ac9b8XxcfMeta?.name ?? "tenancies-agreement-signing",
    path: signing0Ac9b8XxcfMeta?.path ?? "signing",
    meta: signing0Ac9b8XxcfMeta || {},
    alias: signing0Ac9b8XxcfMeta?.alias || [],
    redirect: signing0Ac9b8XxcfMeta?.redirect,
    component: () => import("/home/runner/work/atlantic/atlantic/agents/modules/tenancies/pages/agreement/signing.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexAUpztgCMitMeta?.name ?? "tenancies-agreement-edit",
    path: indexAUpztgCMitMeta?.path ?? "/tenancies/agreement/:uuid",
    meta: indexAUpztgCMitMeta || {},
    alias: indexAUpztgCMitMeta?.alias || [],
    redirect: {"name":"tenancies-agreement-edit-details"},
    component: () => import("/home/runner/work/atlantic/atlantic/agents/modules/tenancies/pages/agreement/[uuid]/index.vue").then(m => m.default || m),
    children: [
  {
    name: detailsq81yrZGX53Meta?.name ?? "tenancies-agreement-edit-details",
    path: detailsq81yrZGX53Meta?.path ?? "details",
    meta: detailsq81yrZGX53Meta || {},
    alias: detailsq81yrZGX53Meta?.alias || [],
    redirect: detailsq81yrZGX53Meta?.redirect,
    component: () => import("/home/runner/work/atlantic/atlantic/agents/modules/tenancies/pages/agreement/details.vue").then(m => m.default || m)
  },
  {
    name: paymentspKOwKrJNBBMeta?.name ?? "tenancies-agreement-edit-payments",
    path: paymentspKOwKrJNBBMeta?.path ?? "payments",
    meta: paymentspKOwKrJNBBMeta || {},
    alias: paymentspKOwKrJNBBMeta?.alias || [],
    redirect: paymentspKOwKrJNBBMeta?.redirect,
    component: () => import("/home/runner/work/atlantic/atlantic/agents/modules/tenancies/pages/agreement/payments.vue").then(m => m.default || m)
  },
  {
    name: tenantsIWn95syl3NMeta?.name ?? "tenancies-agreement-edit-tenants",
    path: tenantsIWn95syl3NMeta?.path ?? "tenants",
    meta: tenantsIWn95syl3NMeta || {},
    alias: tenantsIWn95syl3NMeta?.alias || [],
    redirect: tenantsIWn95syl3NMeta?.redirect,
    component: () => import("/home/runner/work/atlantic/atlantic/agents/modules/tenancies/pages/agreement/tenants.vue").then(m => m.default || m)
  },
  {
    name: deposithfislEOClsMeta?.name ?? "tenancies-agreement-edit-deposit",
    path: deposithfislEOClsMeta?.path ?? "deposit",
    meta: deposithfislEOClsMeta || {},
    alias: deposithfislEOClsMeta?.alias || [],
    redirect: deposithfislEOClsMeta?.redirect,
    component: () => import("/home/runner/work/atlantic/atlantic/agents/modules/tenancies/pages/agreement/deposit.vue").then(m => m.default || m)
  },
  {
    name: signing0Ac9b8XxcfMeta?.name ?? "tenancies-agreement-edit-signing",
    path: signing0Ac9b8XxcfMeta?.path ?? "signing",
    meta: signing0Ac9b8XxcfMeta || {},
    alias: signing0Ac9b8XxcfMeta?.alias || [],
    redirect: signing0Ac9b8XxcfMeta?.redirect,
    component: () => import("/home/runner/work/atlantic/atlantic/agents/modules/tenancies/pages/agreement/signing.vue").then(m => m.default || m)
  },
  {
    name: confirmation96SPXyZjrKMeta?.name ?? "tenancies-agreement-edit-confirmation",
    path: confirmation96SPXyZjrKMeta?.path ?? "confirmation",
    meta: confirmation96SPXyZjrKMeta || {},
    alias: confirmation96SPXyZjrKMeta?.alias || [],
    redirect: confirmation96SPXyZjrKMeta?.redirect,
    component: () => import("/home/runner/work/atlantic/atlantic/agents/modules/tenancies/pages/agreement/confirmation.vue").then(m => m.default || m)
  }
]
  },
  {
    name: previewTTwrAIddr4Meta?.name ?? "tenancies-agreement-preview",
    path: previewTTwrAIddr4Meta?.path ?? "/tenancies/agreement/preview/:uuid",
    meta: previewTTwrAIddr4Meta || {},
    alias: previewTTwrAIddr4Meta?.alias || [],
    redirect: previewTTwrAIddr4Meta?.redirect,
    component: () => import("/home/runner/work/atlantic/atlantic/agents/modules/tenancies/pages/agreement/preview/preview.vue").then(m => m.default || m)
  },
  {
    name: index0x7qVPRCEDMeta?.name ?? "utilities",
    path: index0x7qVPRCEDMeta?.path ?? "/utilities",
    meta: index0x7qVPRCEDMeta || {},
    alias: index0x7qVPRCEDMeta?.alias || [],
    redirect: index0x7qVPRCEDMeta?.redirect,
    component: () => import("/home/runner/work/atlantic/atlantic/agents/modules/utilities/pages/index.vue").then(m => m.default || m)
  },
  {
    name: orderukrtoYjDoSMeta?.name ?? "utilities-order",
    path: orderukrtoYjDoSMeta?.path ?? "/utilities/order",
    meta: orderukrtoYjDoSMeta || {},
    alias: orderukrtoYjDoSMeta?.alias || [],
    redirect: {"name":"utilities-order-details"},
    component: () => import("/home/runner/work/atlantic/atlantic/agents/modules/utilities/pages/order.vue").then(m => m.default || m),
    children: [
  {
    name: details60OcmFc3VHMeta?.name ?? "utilities-order-details",
    path: details60OcmFc3VHMeta?.path ?? "details",
    meta: details60OcmFc3VHMeta || {},
    alias: details60OcmFc3VHMeta?.alias || [],
    redirect: details60OcmFc3VHMeta?.redirect,
    component: () => import("/home/runner/work/atlantic/atlantic/agents/modules/utilities/pages/order/details.vue").then(m => m.default || m)
  },
  {
    name: tenantsPrW44P4LfoMeta?.name ?? "utilities-order-tenants",
    path: tenantsPrW44P4LfoMeta?.path ?? "tenants",
    meta: tenantsPrW44P4LfoMeta || {},
    alias: tenantsPrW44P4LfoMeta?.alias || [],
    redirect: tenantsPrW44P4LfoMeta?.redirect,
    component: () => import("/home/runner/work/atlantic/atlantic/agents/modules/utilities/pages/order/tenants.vue").then(m => m.default || m)
  },
  {
    name: summaryH9oQtXKle8Meta?.name ?? "utilities-order-summary",
    path: summaryH9oQtXKle8Meta?.path ?? "summary",
    meta: summaryH9oQtXKle8Meta || {},
    alias: summaryH9oQtXKle8Meta?.alias || [],
    redirect: summaryH9oQtXKle8Meta?.redirect,
    component: () => import("/home/runner/work/atlantic/atlantic/agents/modules/utilities/pages/order/summary.vue").then(m => m.default || m)
  },
  {
    name: preview1ewtRG27IfMeta?.name ?? "utilities-order-preview",
    path: preview1ewtRG27IfMeta?.path ?? "preview",
    meta: preview1ewtRG27IfMeta || {},
    alias: preview1ewtRG27IfMeta?.alias || [],
    redirect: preview1ewtRG27IfMeta?.redirect,
    component: () => import("/home/runner/work/atlantic/atlantic/agents/modules/utilities/pages/order/preview.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexFoGSUPPCYkMeta?.name ?? "utilities-order-edit",
    path: indexFoGSUPPCYkMeta?.path ?? "/utilities/order/:uuid",
    meta: indexFoGSUPPCYkMeta || {},
    alias: indexFoGSUPPCYkMeta?.alias || [],
    redirect: {"name":"utilities-order-edit-details"},
    component: () => import("/home/runner/work/atlantic/atlantic/agents/modules/utilities/pages/order/[uuid]/index.vue").then(m => m.default || m),
    children: [
  {
    name: details60OcmFc3VHMeta?.name ?? "utilities-order-edit-details",
    path: details60OcmFc3VHMeta?.path ?? "details",
    meta: details60OcmFc3VHMeta || {},
    alias: details60OcmFc3VHMeta?.alias || [],
    redirect: details60OcmFc3VHMeta?.redirect,
    component: () => import("/home/runner/work/atlantic/atlantic/agents/modules/utilities/pages/order/details.vue").then(m => m.default || m)
  },
  {
    name: tenantsPrW44P4LfoMeta?.name ?? "utilities-order-edit-tenants",
    path: tenantsPrW44P4LfoMeta?.path ?? "tenants",
    meta: tenantsPrW44P4LfoMeta || {},
    alias: tenantsPrW44P4LfoMeta?.alias || [],
    redirect: tenantsPrW44P4LfoMeta?.redirect,
    component: () => import("/home/runner/work/atlantic/atlantic/agents/modules/utilities/pages/order/tenants.vue").then(m => m.default || m)
  },
  {
    name: summaryH9oQtXKle8Meta?.name ?? "utilities-order-edit-summary",
    path: summaryH9oQtXKle8Meta?.path ?? "summary",
    meta: summaryH9oQtXKle8Meta || {},
    alias: summaryH9oQtXKle8Meta?.alias || [],
    redirect: summaryH9oQtXKle8Meta?.redirect,
    component: () => import("/home/runner/work/atlantic/atlantic/agents/modules/utilities/pages/order/summary.vue").then(m => m.default || m)
  },
  {
    name: preview1ewtRG27IfMeta?.name ?? "utilities-order-edit-preview",
    path: preview1ewtRG27IfMeta?.path ?? "preview",
    meta: preview1ewtRG27IfMeta || {},
    alias: preview1ewtRG27IfMeta?.alias || [],
    redirect: preview1ewtRG27IfMeta?.redirect,
    component: () => import("/home/runner/work/atlantic/atlantic/agents/modules/utilities/pages/order/preview.vue").then(m => m.default || m)
  },
  {
    name: confirmationXrPqHPgV5eMeta?.name ?? "utilities-order-edit-confirmation",
    path: confirmationXrPqHPgV5eMeta?.path ?? "confirmation",
    meta: confirmationXrPqHPgV5eMeta || {},
    alias: confirmationXrPqHPgV5eMeta?.alias || [],
    redirect: confirmationXrPqHPgV5eMeta?.redirect,
    component: () => import("/home/runner/work/atlantic/atlantic/agents/modules/utilities/pages/order/confirmation.vue").then(m => m.default || m)
  }
]
  }
]